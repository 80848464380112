import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[SkillGroup]';

export const SkillGroupActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  DELETE: `${ACTION_PREFIX} Delete`,
  DELETE_SUCCESS: `${ACTION_PREFIX} Delete Success`,
  DELETE_FAILED: `${ACTION_PREFIX} Delete Failed`,
};

export class SkillGroupAction {
  static load(): Action {
    return {
      type: SkillGroupActionType.LOAD,
    };
  }

  static loadSuccess(SkillGroup): Action {
    return {
      type: SkillGroupActionType.LOAD_SUCCESS,
      payload: SkillGroup,
    };
  }

  static loadFailed(err): Action {
    return {
      type: SkillGroupActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(SkillGroupData): Action {
    return {
      type: SkillGroupActionType.ADD,
      payload: SkillGroupData,
    };
  }

  static addSuccess(SkillGroupResponse): Action {
    return {
      type: SkillGroupActionType.ADD_SUCCESS,
      payload: SkillGroupResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: SkillGroupActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(SkillGroupData): Action {
    return {
      type: SkillGroupActionType.UPDATE,
      payload: SkillGroupData,
    };
  }

  static updateSuccess(SkillGroupResponse): Action {
    return {
      type: SkillGroupActionType.UPDATE_SUCCESS,
      payload: SkillGroupResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: SkillGroupActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static fetch(id: any) {
    return {
      type: SkillGroupActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: SkillGroupActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: SkillGroupActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }

  static delete(id: any) {
    return {
      type: SkillGroupActionType.DELETE,
      payload: id,
    };
  }

  static deleteSuccess(response) {
    return {
      type: SkillGroupActionType.DELETE_SUCCESS,
      payload: response,
    };
  }

  static deleteFailed(id: string, err) {
    return {
      type: SkillGroupActionType.DELETE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
