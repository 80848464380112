import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { SkillGroupSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class SkillGroupApi {
  private endpoint = 'skill_groups/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('SkillGroup', res)),
      map((data) => normalize(data, arrayOf(SkillGroupSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  add(skillGroupData): Observable<any> {
    return this.gateway.post(this.endpoint, skillGroupData).pipe(
      map((res) => reformatEntityResponse('SkillGroup', res)),
      map((data) => normalize(data, SkillGroupSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(id, data): Observable<any> {
    return this.gateway.put(this.endpoint + id, data).pipe(
      map((res) => reformatEntityResponse('SkillGroup', res)),
      map((data) => normalize(data, SkillGroupSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  fetch(skillGroupId): Observable<any> {
    return this.gateway.get(this.endpoint + '/' + skillGroupId).pipe(
      map((res) => reformatEntityResponse('SkillGroup', res)),
      map((data) => normalize(data, SkillGroupSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  delete(id): Observable<any> {
    return this.gateway.delete(this.endpoint + id);
  }
}
