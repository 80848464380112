import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { filterDeleted, mapAndSortEntities, mapEntities, mapEntity } from '../../shared/entity.helper';
import { SkillGroupAction } from './skill-group.action';
import { SkillGroupApi } from './skill-group.api';
import { SkillGroupModel, SkillGroupState } from './skill-group.model';

@Injectable()
export class SkillGroupService {
  constructor(
    private store: Store<AppState>,
    private api: SkillGroupApi,
  ) {}

  load() {
    this.store.dispatch(SkillGroupAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(SkillGroupAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillGroupAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  add(SkillGroupData): Observable<any> {
    this.store.dispatch(SkillGroupAction.add(SkillGroupData));

    return this.api.add(SkillGroupData).pipe(
      map((response) => {
        this.store.dispatch(SkillGroupAction.addSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillGroupAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  update(id, SkillGroupData) {
    this.store.dispatch(SkillGroupAction.update(SkillGroupData));

    return this.api.update(id, SkillGroupData).pipe(
      map((response) => {
        this.store.dispatch(SkillGroupAction.updateSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillGroupAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  fetch(id) {
    this.store.dispatch(SkillGroupAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(SkillGroupAction.fetchSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillGroupAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  save(skillGroupData) {
    if (skillGroupData.id) {
      return this.update(skillGroupData.id, skillGroupData);
    }

    skillGroupData = u.omit('id', skillGroupData);

    return this.add(skillGroupData);
  }

  delete(id: string) {
    this.store.dispatch(SkillGroupAction.delete(id));

    return this.api.delete(id).pipe(
      map((response) => {
        this.store.dispatch(SkillGroupAction.deleteSuccess(id));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillGroupAction.deleteFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }
}

export const getSkillGroupState = (appState: AppState): SkillGroupState => appState.orm.skillGroups;

export const getSkillGroupIds = compose((state) => state.items, getSkillGroupState);

export const sortSkillGroups = (skillGroup: SkillGroupModel[]): SkillGroupModel[] => sortBy(skillGroup, ['name']);
export const mapAndSortSkillsGroups = mapAndSortEntities(sortSkillGroups);

export const getSkillGroupEntities = createSelector(getSkillGroupState, (state) => state.itemsById);
export const getSkillGroups = createSelector(getSkillGroupIds, getSkillGroupEntities, (skillGroupIds, entities) => {
  const skillGroups = mapAndSortSkillsGroups(skillGroupIds, entities);
  return filterDeleted(skillGroups);
});

export const getSkillGroup = (id: string) =>
  createSelector(getSkillGroupEntities, (entities) => mapEntity(id, entities));
